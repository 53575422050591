// This file resets the layout so it's ready for use as a splash page
// - Hides necessary items
// - Adjusts positoning due to hiding certain elements
//
// Works best when the splash can be formatted with an Admin theme background
// and the interactive items can be managed using photo/link elements
//
html.collapsed-mobile-nav .site-background {
  margin-top: 0;
}
html:not(.collapsed-mobile-nav):not(.has-sub-nav) .site-background {
  margin-top: 0;
}
.user_mode .site-banner-wrapper {
  background-position: center -15px;
  background-size: 150% auto;
  background-repeat: no-repeat;
  height: 125px;
}
.site-background {
  background-position:center top;
  background-size:cover;
  top:0 !important;
  left:0 !important;
}
#siteContainer {
  min-height: auto;
}
.heroPhotoElement img {
  border:none;
  max-width:80%;
}
footer.snFooterContainer {
  max-width:100%;
  bottom:0;
}
.user_mode {
  #displayBodyHeader,
  #topNav,
  #topNavPlaceholder {
    display: none;
  }
}
.has-mobile-nav body.user_mode:before {
  display: none;
}
body {
  background-color: $background-color;
}
#siteContainer,
.snFooterContainer {
  background: none;
}

@media only screen and (min-width: 768px) {
  .user_mode .site-banner-wrapper {
    background-position: center -15px;
    background-size: 100% auto;
    height: auto;
  }
}

@media only screen and (min-width: 1024px) {
  body.noUserBar {
    padding-top: 44px;
  }
  html.has-main-nav:not(.has-sub-nav) #siteHeader {
    margin-top: 0;
  }
}
