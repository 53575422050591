@import url("https://use.typekit.net/lbe7ycy.css");

.user_mode #siteContainer .yieldPageContent {
   padding-top: 0;
}
.user_mode #siteContainer {
   margin:0 auto;
   max-width:1440px;
   padding-top:0;
}
.user_mode .splash-container {
  background-color:transparent;
  max-width:1440px;
  height:80vh;
}
.splash-left {
  img {
    height:143px;
    width:109px;
  }
}
.splash-right {
  img {
    height:142px;
    width:131px;
  }
}
.splash-left, .splash-right {
  img {
    max-width:none !important;
    &:hover {
      transition:.25s;
      transform:scale(1.1);
    }
  }
 .textBlockElement {
   display:flex;
   flex-direction:column;
   justify-content:flex-start;
   align-items:center;
   h3 {
     color:#fff;
     font-family:"breakers-slab", helvetica, sans-serif;
     font-size:30px;
     font-style:normal;
     font-weight:700;
     margin:10px 0;
     line-height:30px;
     text-align:center;
     text-shadow:0px 3px 4px #000000;
   }
 }
}
#siteFooter > ul a {
  color:#000;
  &:hover {
    color:#000;
  }
}
// Tablet
@media only screen and (min-width: 768px) {
   .has-mobile-nav body.user_mode {
      padding-top: 44px;
   }
   .user_mode footer.snFooterContainer {
      margin-top: 75px;
   }
   .splash-left, .splash-right {
    .textBlockElement {
      h3 {
        font-size:50px;
        line-height:50px;
        text-align:left;
      }
    }
   }
}
// Desktop
@media only screen and (min-width: 1024px) {
  .user_mode #siteContainer {
     padding-top:30px;
  }
   .user_mode footer.snFooterContainer {
      margin-top:0;
   }
   .splash-left, .splash-right {
      .textBlockElement {
        flex-direction:row;
      }
   }
   #poweredByNGIN {
     padding:5px 0 !important;
   }
}
